import { t } from '@/utility/localization';
import NextImage from '@/components/common/NextImage';
import { formatNumber } from '@/utility/helpers';
import classNames from 'classnames';

const componentClassName = 'c-CommunitySignupModal-CommunityOverview';

const CommunityOverview = ({ communityInfo, priceTagText }) => {
  const { isPaidCommunity, title, profileImage, applicationConfig } =
    communityInfo;

  const totalMemberCount =
    communityInfo?.totalMemberCount || communityInfo?.memberCount;

  const isApplicationBasedCommunity =
    communityInfo.request_approval || applicationConfig?.requestApproval;

  const hideTotalMemberCount = communityInfo?.config?.hideMemberCount;

  return (
    <div
      className={classNames(
        componentClassName,
        'flex flex-col items-center'
      )}>
      <div className="h-64 w-64">
        <NextImage
          alt="community-image"
          className="rounded-8"
          mobileImgProps={{
            src: profileImage,
            layout: 'responsive',
            objectFit: 'cover',
            width: 64,
            height: 64
          }}
        />
      </div>
      <div className="mt-16 space-y-8 text-center">
        <p className="text-heading-md font-semibold text-npl-text-icon-on-light-surface-primary">
          {title}
        </p>
        <p className="text-label-lg font-medium text-npl-text-icon-on-light-surface-secondary">
          {!hideTotalMemberCount &&
            `${formatNumber(totalMemberCount)} ${
              totalMemberCount === 1 ? t('member1') : t('members1')
            }`}
          {isPaidCommunity &&
            !hideTotalMemberCount &&
            priceTagText &&
            ' • '}
          {isPaidCommunity && priceTagText ? priceTagText : ''}
        </p>
        {isApplicationBasedCommunity &&
          !applicationConfig?.autoApproval && (
            <p className="text-label-md text-npl-text-icon-on-light-surface-tertiary">
              {t('approval-required')}
            </p>
          )}
      </div>
    </div>
  );
};

export default CommunityOverview;
