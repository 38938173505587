import ResponsiveModal from '@/components/common/ResponsiveModal';
import useQueryParams from '@/hooks/useQueryParams';
import { PendingApplicationApprovalModal } from '@/pages/checkout-global/components/PendingApplicationApprovalModal/PendingApplicationApprovalModal';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';

const WithPendingCommMembershipApprovalModal = () => {
  const [showPendingApprovalModal, setShowPendingApprovalModal] =
    useState(false);
  const router = useRouter();
  const pathname = usePathname();
  const { currentRouterQuery } = useQueryParams();
  const paymentMade = currentRouterQuery?.paymentMade === '1';

  const handleApplicationPendingModalClose = () => {
    setShowPendingApprovalModal(false);

    // Remove showMembershipPendingApproval and paymentMade from the URL
    const newQuery = { ...currentRouterQuery };
    delete newQuery.showMembershipPendingApproval;
    if (newQuery.paymentMade) delete newQuery.paymentMade;
    router.replace({
      pathname: pathname,
      query: newQuery
    });
  };

  useEffect(() => {
    const showMembershipPendingApprovalQuery =
      currentRouterQuery?.showMembershipPendingApproval === '1';

    if (showMembershipPendingApprovalQuery) {
      setShowPendingApprovalModal(true);
    }
  }, [currentRouterQuery]);

  return showPendingApprovalModal ? (
    <ResponsiveModal
      open={showPendingApprovalModal}
      showCloseIcon={false}
      closeOnBackdropClick={false}
      customContainerClass="md:max-w-[480px]"
      customInnerClass="!p-0">
      <PendingApplicationApprovalModal
        withRefundNote={paymentMade} // if payment is made, then need to show that user will be refunded on rejection
        onClose={handleApplicationPendingModalClose}
      />
    </ResponsiveModal>
  ) : null;
};

export default WithPendingCommMembershipApprovalModal;
