import { signUpCheck } from '@/services/communitiesService';
import { validateEmail } from '@/utility/formHelpers';
import { t } from '@/utility/localization';
import React, { useEffect, useState } from 'react';
import { COMMUNITY_SIGNUP_FORM_STATE } from '../constants';
import CommunityOverview from './CommunityOverview';
import { useAuthContext } from '@/contexts/AuthContext';
import { showErrorToast } from '@/components/common/ToastContainer';
import NPLInput from '@/components/npl/NPLInput/NPLInput';
import NPLButton from '@/components/npl/NPLButton';
import SocialLogins from '@/components/common/SocialLogins';

const EmailOrSocialLoginForm = (props) => {
  const {
    communityInfo,
    signupFormValues,
    setSignUpFormValues,
    setFormState,
    priceTagText,
    handleCommunitySignup,
    handlePostLoginCallback
  } = props;

  const [email, setEmail] = useState(signupFormValues?.email || '');
  const [emailError, setEmailError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    signupNewMemWithDefPwd,
    handleSocialAuth,
    socialLoginError,
    isLoading: isSocialLoginLoading
  } = useAuthContext();

  const onEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const trimmedEmailValue = email.trim();
      const isEmailValid = await validateEmail(trimmedEmailValue);

      if (!isEmailValid) {
        setEmailError(t('email-is-invalid'));
        return;
      }

      setIsSubmitting(true);

      // Check if it's an existing user or new user
      const { data, error } = await signUpCheck(trimmedEmailValue);

      if (error) {
        throw new Error(error);
      }

      const { userExists, isActiveUser, token } = data;

      if (userExists) {
        setSignUpFormValues((prev) => ({
          ...prev,
          email: trimmedEmailValue,
          //   userEmail: trimmedEmailValue,
          //   inputEmail: trimmedEmailValue,
          isActiveUser
        }));

        setIsSubmitting(false);
        setFormState({
          current: COMMUNITY_SIGNUP_FORM_STATE.OTP,
          previous: COMMUNITY_SIGNUP_FORM_STATE.EMAIL_WITH_SOCIAL_LOGIN
        });
      } else {
        await signupNewMemWithDefPwd({ email: trimmedEmailValue, token });
        handleCommunitySignup({ email: trimmedEmailValue });
      }
    } catch (e) {
      setIsSubmitting(false);
      showErrorToast(String(e));
    }
  };

  const handleSocialAuthMiddleware = async (params) => {
    const response = await handleSocialAuth(params);
    if (response?.error) {
      showErrorToast(response.error);
      setIsSubmitting(false);
      return;
    }

    const { user } = response?.data || {};
    if (user) {
      handlePostLoginCallback(user);
    }
  };

  // This useEffect is to initialize signUpFormValues.email
  // When it exist in the queryParams on initial render
  useEffect(() => {
    if (signupFormValues?.email) {
      setEmail(signupFormValues?.email);
    }
  }, [signupFormValues?.email]);

  return (
    <div className="c-CommunitySignupModal-EmailOrSocialLoginForm p-24">
      <CommunityOverview
        communityInfo={communityInfo}
        priceTagText={priceTagText}
      />
      {/* Email Form */}
      <form onSubmit={onSubmit} className="mt-24">
        <NPLInput
          placeholder={t('email')}
          value={email}
          onInput={onEmailChange}
          error={emailError}
        />
        <div className="mt-24">
          <NPLButton
            stretch
            size="lg"
            hierarchy="accent_primary"
            loading={isSubmitting}
            type="submit"
            buttonText={t('continue-with-email')}
          />
        </div>
      </form>
      {/* Or */}
      <div className="my-8 h-16 w-full text-center text-body-xs text-grey-light">
        {t('or1')}
      </div>
      {/* Social Auth */}
      <SocialLogins
        // isRounded
        isLoading={isSocialLoginLoading}
        handleSocialAuth={handleSocialAuthMiddleware}
        socialLoginError={socialLoginError}
        customClassNames="w-full"
      />
    </div>
  );
};

export default EmailOrSocialLoginForm;
