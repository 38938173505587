import { t } from '@/utility/localization';
import { showErrorToast } from '@/components/common/ToastContainer';
import { useCallback, useEffect, useState } from 'react';
import { sendOtpToEmail } from '@/services/userService';
import OtpInTextField from '@/components/common/OtpInTextField';
import { useAuthContext } from '@/contexts/AuthContext';
import { COMMUNITY_SIGNUP_FORM_STATE } from '../constants';
import NPLButton from '@/components/npl/NPLButton';
import TitleRow from './TitleRow';
import { MAX_OTP_LENGTH } from '@/utility/otpUtils';

const COUNTDOWN_SECONDS = 30;

const OtpForm = ({
  signupFormValues,
  setSignUpFormValues,
  setFormState,
  handlePostLoginCallback,
  onBack
}) => {
  const { email, isActiveUser } = signupFormValues;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [otpToken, setOtpToken] = useState('');

  const { loginWithOtpValidation } = useAuthContext();

  const handleSendOtpToEmail = useCallback(async () => {
    if (!email) {
      return;
    }
    const { data, error } = await sendOtpToEmail(email);

    if (!error) {
      setOtpToken(data.otpToken);
    } else {
      showErrorToast(error);
    }
  }, [email]);

  const handleSignUpAfterOtpValidation = async (response) => {
    const { user } = response?.data || {};
    if (user) {
      handlePostLoginCallback(user);
    }
  };

  const onSubmit = async (e) => {
    e?.preventDefault();
    setIsSubmitting(true);

    const response = await loginWithOtpValidation({
      otpToken,
      email,
      otp: otpCode
    });

    const { error, data } = response;

    if (error) {
      showErrorToast(error);
    } else {
      setSignUpFormValues((prev) => ({
        ...prev,
        passwordToken: data.passwordToken
      }));

      await handleSignUpAfterOtpValidation(response);
    }

    setIsSubmitting(false);
  };

  const handleOnChangeOtp = (value) => {
    setOtpCode(value);
  };

  useEffect(() => {
    handleSendOtpToEmail();
    //only call this once when page loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (otpCode?.length === MAX_OTP_LENGTH) {
      onSubmit();
    }
    // onSubmit need not be part of the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpCode]);

  return (
    <div className="c-CommmunitySignupModal-OtpForm relative flex w-full flex-col items-center p-24">
      <TitleRow title={t('enter-verification-code')} onBack={onBack} />
      {/* Desc */}
      <p className="mt-8 text-center text-body-sm font-medium text-dark-1b text-opacity-65">
        {t(
          'verification-code-sent-to-email-if-you-dont-see-it-please-check-your-spam-folder',
          { email }
        )}
      </p>
      {/* Form */}
      <form className="mt-32 w-full" onSubmit={onSubmit}>
        <div className="mb-24 w-full">
          <OtpInTextField
            type="number"
            name="otpCode"
            placeholder={t('verification-code')}
            label={t('verification-code')}
            focus
            onChangeValue={handleOnChangeOtp}
            onResend={handleSendOtpToEmail}
            defaultCountdown={COUNTDOWN_SECONDS}
          />
        </div>

        <div className="space-y-12">
          <NPLButton
            stretch
            size="lg"
            type="submit"
            hierarchy="accent_primary"
            loading={isSubmitting}
            disabled={!otpCode || isSubmitting}
            buttonText={t('continue')}
          />
          {isActiveUser && (
            <NPLButton
              size="lg"
              stretch
              hierarchy="outline"
              buttonText={t('use-password')}
              onClick={() => {
                setFormState({
                  current: COMMUNITY_SIGNUP_FORM_STATE.LOGIN_WITH_PASSWORD,
                  previous: COMMUNITY_SIGNUP_FORM_STATE.OTP
                });
              }}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default OtpForm;
