import {
  getCommunityPricingByDiscountCodeService,
  getCommunityPricingServiceV2
} from '@/services/communitiesService';
import {
  formatNewPricingApiToOldFormat,
  getCommunityPriceTagText
} from '@/utility/communityHelpers';
import { getDiscountCodeFromUrlParams } from '@/utility/routesHelper';
import { useEffect, useMemo, useState } from 'react';
import { DISCOUNT_ENTITY_TYPE } from './useDiscount';
import { formatPrice } from '@/features/community/utils';
import useQueryParams from './useQueryParams';

const useCommunityPricingData = ({ community }) => {
  const serverSidePriceData = community?.pricingData
    ? formatPrice(community?.pricingData)
    : null;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [pricingData, setPricingData] = useState(serverSidePriceData);
  const [selectedPricingOption, setSelectedPricingOption] = useState(null);
  // discounting states
  const [discountCode, setDiscountCode] = useState(null);
  const [discountedCodeData, setDiscountedCodeData] = useState();

  // community data
  const communityCode = community?.code;
  const communityId = community?._id;
  const stripeProductId =
    community?.stripeProductId || community?.pricingData?.productId;
  const isFreeCommunity =
    community?.isFreeCommunity || !community?.isPaidCommunity;
  const needsApplicationReview =
    (community?.request_approval ||
      community?.applicationConfig?.requestApproval) &&
    !community?.applicationConfig?.autoApproval;

  // discounting data
  const { currentRouterQuery } = useQueryParams();

  const discountCodeFromQueryParams =
    getDiscountCodeFromUrlParams(currentRouterQuery);

  const showPricing = useMemo(() => {
    return pricingData || isFreeCommunity;
  }, [pricingData, isFreeCommunity]);

  const firstPricingData = useMemo(() => {
    if (!pricingData || !pricingData.length) {
      return null;
    }
    return pricingData[0];
  }, [pricingData]);

  const { currency, pricePerMonth } = useMemo(() => {
    const currency = selectedPricingOption?.currency?.toUpperCase() || '';

    const interval =
      selectedPricingOption?.recurring?.interval === 'year'
        ? 12
        : selectedPricingOption?.recurring?.interval_count;
    return {
      currency,
      pricePerMonth: selectedPricingOption?.unit_amount / interval / 100
    };
  }, [selectedPricingOption]);

  const discountedPricePerMonth = useMemo(() => {
    if (discountedCodeData) {
      const { type, value } = discountedCodeData;
      if (type === 'percentage') {
        return parseFloat(
          Number(pricePerMonth * (1 - value / 100)).toFixed(2)
        ).toLocaleString();
      }
    }

    return null;
  }, [discountedCodeData, pricePerMonth]);

  const priceTagText = useMemo(() => {
    return getCommunityPriceTagText({
      pricingData,
      interval: selectedPricingOption?.recurring?.interval,
      currency: selectedPricingOption?.currency,
      discountedCodeData,
      isFreeCommunity,
      needsApplicationReview,
      discountedPricePerMonth,
      // nftOnly,
      pricePerMonth,
      showSubtitle: true
    });
  }, [
    pricingData,
    selectedPricingOption?.recurring?.interval,
    selectedPricingOption?.currency,
    discountedCodeData,
    isFreeCommunity,
    needsApplicationReview,
    discountedPricePerMonth,
    // nftOnly,
    pricePerMonth
  ]);

  useEffect(() => {
    const fetchPricingData = async () => {
      try {
        setLoading(true);
        setError('');

        const { data, error } =
          await getCommunityPricingServiceV2(communityId);

        if (error) {
          console.error('Error fetching community data', error);
          setError(error);
          setLoading(false);
          return;
        }

        const formattedNewPricingDetails =
          formatNewPricingApiToOldFormat(data);

        formattedNewPricingDetails?.sort(function (a, b) {
          return (
            a?.recurring?.interval_count - b?.recurring?.interval_count
          );
        });

        setPricingData(formattedNewPricingDetails);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };

    // if page in fallback it means its being built, so leave in loading state, else set loading false
    if (communityId && stripeProductId) {
      fetchPricingData();
    }
  }, [communityId, stripeProductId]);

  useEffect(() => {
    if (showPricing && !selectedPricingOption) {
      setSelectedPricingOption(firstPricingData);
    }
  }, [
    pricePerMonth,
    pricingData,
    firstPricingData,
    selectedPricingOption,
    showPricing
  ]);

  useEffect(() => {
    const getDiscountedPrice = async (discountCode) => {
      const { data, error } =
        await getCommunityPricingByDiscountCodeService({
          discountCode,
          communityCode,
          entityObjectId: communityId,
          entityType: DISCOUNT_ENTITY_TYPE.SUBSCRIPTION
        });
      if (error) {
        return;
      }
      setDiscountedCodeData(data);
    };

    if (
      discountCodeFromQueryParams &&
      discountCodeFromQueryParams.length > 0
    ) {
      setDiscountCode(discountCodeFromQueryParams);
      getDiscountedPrice(discountCodeFromQueryParams);
    }
  }, [communityCode, communityId, discountCodeFromQueryParams]);

  return {
    loading,
    error,
    pricingData,
    discountedCodeData,
    priceTagText,
    currency,
    discountCode,
    isFreeCommunity,
    isApplicationBased: needsApplicationReview
  };
};

export default useCommunityPricingData;
