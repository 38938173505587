import Icon from '@/components/npl/Icon';
import React from 'react';

const TitleRow = ({ title, onBack }) => {
  const showBackBtn = !!onBack;
  return (
    <div className="relative flex w-full justify-center">
      {/* Back Button */}
      {showBackBtn && (
        <button className="absolute left-0" onClick={onBack}>
          <Icon
            name="arrow-left"
            width={24}
            height={24}
            fillOpacity="0.5"
          />
        </button>
      )}
      {/* Title */}
      <h2 className="text-heading-sm font-semibold text-dark-1b">
        {title}
      </h2>
    </div>
  );
};

export default TitleRow;
