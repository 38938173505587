import { useRef, useState } from 'react';
import usePixelTracking from './usePixelTracking';
import { getDiscountCodeFromUrlParams } from '@/utility/routesHelper';
import useCommunityPricingData from './useCommunityPricingData';
import useQueryParams from './useQueryParams';

/**
 * Custom hook for retrieving common public page data.
 * @param {Object} props - The props object.
 * @param {Object} props.communityInfo - The community information object.
 * @returns {Object} - The common public page data.
 * @property {boolean} fetchingUserCommunities - Indicates if user communities are being fetched.
 * @property {Array} userCommunities - The user communities.
 * @property {boolean} isPendingApproval - Indicates if the user is pending approval in the community.
 * @property {boolean} isCommunityAdmin - Indicates if the user is a community admin.
 * @property {boolean} isCommunityMember - Indicates if the user is a community member.
 */
const useCommonPublicPageData = (props) => {
  const { currentRouterQuery } = useQueryParams();
  const { communityInfo } = props;
  const communityId = communityInfo?._id;
  const isDemoCommunity = communityInfo?.isDemo;

  const [isSignUpModalOpened, setIsSignUpModalOpened] = useState(false);
  const signUpPopupRef = useRef();
  const discountCodeFromQueryParams =
    getDiscountCodeFromUrlParams(currentRouterQuery);

  // Community pricing data
  const { pricingData, priceTagText, discountedCodeData } =
    useCommunityPricingData({
      community: communityInfo
    });

  // Load pixel if data is available.
  usePixelTracking({
    communityId,
    trackingPixels: communityInfo?.trackingPixels,
    postInitProcess: {
      trackPageView: true
    }
  });

  return {
    // community data
    isDemoCommunity,
    // signup popup
    isSignUpModalOpened,
    setIsSignUpModalOpened,
    signUpPopupRef,
    discountCodeFromQueryParams,
    pricingData,
    priceTagText,
    discountedCodeData
  };
};

export default useCommonPublicPageData;
