import React, { useEffect, useState } from 'react';
import { COMMUNITY_SIGNUP_FORM_STATE } from './constants';
import Modal from '@/components/common/Modal';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import PopOverModal from '@/components/common/PopOverModal';
import { useRouter } from 'next/navigation';
import { getCommunityCheckoutRoute } from '@/utility/routesHelper';
import EmailOrSocialLoginForm from './components/EmailOrSocialLoginForm';
import {
  checkMember,
  unifiedCheckoutSignup
} from '@/services/communitiesService';
import {
  getTrackingInformationForBE,
  trackBeginCheckout,
  trackGAEvent,
  trackInitiateCheckout
} from '@/utility/analytics';
import {
  getCheckoutRequestor,
  getSourceInfo
} from '@/hooks/utils/communityMembersUtils';
import useLocale from '@/hooks/useLocale';
import { getTimezoneId } from '@/utility/dateHelper';
import { showErrorToast } from '@/components/common/ToastContainer';
import { SIGNUP_SUCCESS } from '@/utility/analyticsConsts';
import OtpForm from './components/OtpForm';
import LoginWithPassword from './components/LoginWithPassword';
import { useAuthContext } from '@/contexts/AuthContext';
import LoadingCircle from '@/components/common/LoadingCircle';
import { t } from '@/utility/localization';
import { SIGNUP_ORIGIN } from '../SignUpPopup/constants';
import useQueryParams from '@/hooks/useQueryParams';

const CommunitySignupModal = (props) => {
  const {
    open,
    onClose,
    communityInfo,
    isCommunityMember,
    discountCodeFromQueryParams,
    signUpOriginProps = {},
    // newMemberSignupCallback is called after a new member is signed up to free community
    newMemberSignupCallback,
    // When existing member logs in
    existingMemberLoginCallback // previously onCheckout and onResourceOriginSignUpSuccess
  } = props;

  const { currentLocale } = useLocale();
  const router = useRouter();
  const { currentRouterQuery } = useQueryParams();
  const { isLoggedIn, user } = useAuthContext();

  const { isGtEqMd } = useWindowWidthContext();
  const [formState, setFormState] = useState({
    // current: COMMUNITY_SIGNUP_FORM_STATE.EMAIL_WITH_SOCIAL_LOGIN
    current: ''
  });
  const [signupFormValues, setSignUpFormValues] = useState({
    email: '',
    isCommunityMember
  });

  const {
    _id: communityId,
    request_approval,
    isPaidCommunity,
    applicationConfig
  } = communityInfo;

  const checkoutTrackingObject = {
    isWhatsappCommunity: !!communityInfo?.isWhatsappExperienceCommunity,
    type: communityInfo?.isPaidCommunity ? 'paid' : 'free',
    community_name: communityInfo?.title,
    community_id: communityId
  };
  const isApplicationBasedComm =
    request_approval || applicationConfig?.requestApproval;
  const isCommunityCheckoutRequired =
    (isApplicationBasedComm || isPaidCommunity) &&
    !signupFormValues.isCommunityMember; // TODO: @AmanMinhas - check if there is any use for isCommunityMember
  const isEntityCheckoutRequired =
    signUpOriginProps?.isPaid ||
    signUpOriginProps?.eventInfo?.applicationConfigDataFields?.length > 0; //if event has application form
  const isCheckoutFlowRequired =
    isCommunityCheckoutRequired || isEntityCheckoutRequired;

  const modalEyebrowText =
    formState.current ===
    COMMUNITY_SIGNUP_FORM_STATE.EMAIL_WITH_SOCIAL_LOGIN
      ? signUpOriginProps?.signUpOrigin === SIGNUP_ORIGIN.POST_PAGE
        ? t('join-to-read-this-post')
        : t('sign-up-or-log-in')
      : '';

  const handleGoBackFormState = () => {
    setFormState((prevState) => {
      if (prevState.previous) {
        return {
          current: prevState.previous,
          previous: prevState.current
        };
      }

      return prevState;
    });
  };

  const handleCommunitySignupSuccess = ({ bookingId }) => {
    // what todo after community membership signup
    newMemberSignupCallback?.({ bookingId });
    onClose();
  };

  const handleFreeCommunitySignup = async ({ email }) => {
    // - signup to free community
    // - CALL handleCommSignupSuccess

    const paymentProvider =
      communityInfo?.payment_methods?.[0]?.value || 'stripe';
    let memberInfo = {
      email,
      languagePreference: currentLocale
    };

    const payload = {
      communityCode: communityInfo?.code,
      timezone: getTimezoneId(),
      trackingData: getTrackingInformationForBE(),
      requestor: getCheckoutRequestor({ communityInfo }),
      paymentProvider,
      memberInfo,
      items: [
        {
          type: 'SUBSCRIPTION'
        }
      ]
    };

    const { data, error } = await unifiedCheckoutSignup(payload);

    if (error) {
      showErrorToast(error);
      return;
    }

    const bookingId = data?.enrollmentStatus?.purchaseTransactionObjectId;

    if (data?.enrollmentStatus?.memberEnrolled) {
      handleCommunitySignupSuccess({ bookingId });
      trackGAEvent(SIGNUP_SUCCESS, checkoutTrackingObject);
    }
  };

  const goToCheckoutPage = () => {
    // redirect to checkout
    const {
      eventInfo,
      resourceInfo,
      challengeInfo,
      postInfo,
      signUpOrigin,
      entityMetadata
    } = signUpOriginProps;

    const sourceInfo = getSourceInfo({
      signUpOrigin,
      eventInfo,
      resourceInfo,
      challengeInfo,
      postInfo,
      entityMetadata
    });

    // const selectedPricingOption = getLowestIntervalPricing(
    //   communityInfo?.pricingData
    // );

    const checkoutRoute = getCommunityCheckoutRoute({
      communityId: communityInfo?._id,
      communityCode: communityInfo?.code,
      // interval: selectedPricingOption?.recurring?.interval ?? 'month',
      // intervalCount: selectedPricingOption?.recurring?.interval_count ?? 1,
      sourceInfo,
      requestor: getCheckoutRequestor({ communityInfo }),
      discountCode: sourceInfo ? null : discountCodeFromQueryParams,
      entityDiscountCode: sourceInfo ? discountCodeFromQueryParams : null
    });

    router.push(checkoutRoute);
  };

  const handleCommunitySignup = async ({ email }) => {
    // analytics
    // check if checkout flow is required and redirect to checkout
    // if not, do community signup
    // handleCommSignupSuccess

    // analytics
    trackBeginCheckout(checkoutTrackingObject);
    trackInitiateCheckout(checkoutTrackingObject);

    // check if checkout flow is required and redirect to checkout
    if (isCheckoutFlowRequired) {
      goToCheckoutPage();
      return;
    }

    // if checkout not required, do community signup
    await handleFreeCommunitySignup({ email });
  };

  const handlePostLoginCallback = async (user) => {
    // check if user is a member
    // if is a member then existingMemberLoginCallback
    // if not, then handleCommunitySignup

    // check if user is a member
    const { data } = await checkMember(communityId);
    const { isSubscriber } = data ?? {};

    if (isSubscriber) {
      if (isEntityCheckoutRequired) {
        goToCheckoutPage();
      }
      existingMemberLoginCallback();
      onClose();
      return;
    }

    handleCommunitySignup({ email: user.email });
  };

  const renderActiveFormState = () => {
    switch (formState.current) {
      case COMMUNITY_SIGNUP_FORM_STATE.EMAIL_WITH_SOCIAL_LOGIN:
        return (
          <EmailOrSocialLoginForm
            communityInfo={communityInfo}
            signupFormValues={signupFormValues}
            setSignUpFormValues={setSignUpFormValues}
            setFormState={setFormState}
            handleCommunitySignup={handleCommunitySignup}
            handlePostLoginCallback={handlePostLoginCallback}
          />
        );
      case COMMUNITY_SIGNUP_FORM_STATE.OTP:
        return (
          <OtpForm
            signupFormValues={signupFormValues}
            setSignUpFormValues={setSignUpFormValues}
            setFormState={setFormState}
            handlePostLoginCallback={handlePostLoginCallback}
            onBack={handleGoBackFormState}
          />
        );
      case COMMUNITY_SIGNUP_FORM_STATE.LOGIN_WITH_PASSWORD:
        return (
          <LoginWithPassword
            signupFormValues={signupFormValues}
            setFormState={setFormState}
            handlePostLoginCallback={handlePostLoginCallback}
            onBack={handleGoBackFormState}
          />
        );
      default:
        return (
          <div className="p-24">
            <LoadingCircle />
          </div>
        );
    }
  };

  const renderInner = () => {
    return renderActiveFormState();
  };

  const getInitialScreenState = () => {
    if (isLoggedIn && user) {
      handlePostLoginCallback(user);
    } else {
      setFormState({
        current: COMMUNITY_SIGNUP_FORM_STATE.EMAIL_WITH_SOCIAL_LOGIN
      });
    }
  };

  useEffect(() => {
    setSignUpFormValues((prev) => ({
      ...prev,
      email: currentRouterQuery?.email
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRouterQuery?.email]);

  useEffect(() => {
    if (open) {
      getInitialScreenState();
    }
  }, [open]);

  return (
    <div>
      {isGtEqMd ? (
        <Modal
          open={open}
          onClose={onClose}
          size="md-420"
          customInnerClass="!p-0"
          eyebrow={modalEyebrowText}
          customEyeBrowClass="!absolute !text-heading-sm !font-medium !top-[-48px] !text-center"
          customBackdropClassNames="!bg-white-default !bg-opacity-75 !backdrop-blur-lg"
          withOutsideCloseIcon
          customCloseIconClass="!fixed !top-12 !right-12"
          showNasLogoAtTopLeft>
          {renderInner()}
        </Modal>
      ) : (
        <PopOverModal
          open={open}
          onClose={onClose}
          customInnerClass="!p-0"
          withOutsideCloseIcon
          customCloseIconClass="!fixed !bg-transparent !top-12 !right-12"
          eyebrow={modalEyebrowText}
          customEyebrowClass="!absolute !text-heading-sm !font-medium !top-[-48px] !text-center"
          customBackdropClassNames="!bg-white-default !bg-opacity-75 !backdrop-blur-lg"
          showNasLogoAtTopLeft>
          {renderInner()}
        </PopOverModal>
      )}
    </div>
  );
};

export default CommunitySignupModal;
